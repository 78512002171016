import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IGenericResponse, Backend } from 'src/app/shared/models/GenericResponse'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  constructor(private http: HttpClient) { }

  getServices(data): Observable<IGenericResponse> {    
    const url = `${Backend.config().api_Url}/locations/statistics`
    return this.http.post<IGenericResponse>(url, data);
  }

  genReport(data): Observable<IGenericResponse> {    
    const url = `${Backend.config().api_Url}/locations/report/orders`
    return this.http.post<IGenericResponse>(url, data);
  }

  saveMainAddress(data):Observable<IGenericResponse> {    
    const url = `${Backend.config().api_Url}/locations/address/main/save`
    return this.http.post<IGenericResponse>(url, data);
  }

  getAddresses(id):Observable<IGenericResponse> {    
    const url = `${Backend.config().api_Url}/locations/address/${id}`
    return this.http.get<IGenericResponse>(url);
  }

  toggleService(data):Observable<IGenericResponse> {    
    const url = `${Backend.config().api_Url}/locations/service/toggle`;
    return this.http.post<IGenericResponse>(url, data);
  }

}
